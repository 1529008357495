(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name people.edit.controller:EditCtrl
   *
   * @description
   *
   */
  angular
    .module('people.edit')
    .controller('EditCtrl', EditCtrl);

  function EditCtrl($state, $stateParams, $mdDialog, Restangular) {
    var vm = this;
    vm.submit = submit;
    vm.deletePerson = deletePerson;
    vm.showConfirmDelete = showConfirmDelete;

    vm.person = {};
    vm.created_at = '';
    vm.updated_at = '';
    vm.isEditing = $state.is('people.edit');
    vm.errors = [];

    if (vm.isEditing) {
      Restangular.one('people', $stateParams.personId).get({inc:"Address"}).then(function (response) {
        vm.person = response.data;
        vm.created_at = moment(vm.person.created_at).format('Do MMM YYYY');
        vm.updated_at = moment(vm.person.updated_at).format('Do MMM YYYY');
      });
    } else {
      vm.person.email_setting = 'all';
      vm.person.sms_setting = 'all';
      vm.person.post_setting = 'all';
    }

    function submit() {
      if (vm.isEditing) {
        Restangular.all('people').customPUT(vm.person, vm.person.id).then(submitSuccess, submitFailure);
      } else {
        Restangular.all('people').post(vm.person).then(submitSuccess, submitFailure);
      }
    }

    function submitSuccess(response) {
      var id = vm.isEditing ? vm.person.id : response.data.id;
      $state.go('people.view', {personId: id});
    }

    function submitFailure(response) {
      console.log('person submit failed', response.data.errors);
      vm.errors = response.data.errors;
    }

    function deletePerson() {
      Restangular.one('people', vm.person.id).remove().then(deleteSuccess, deleteFailure);
    }

    function deleteSuccess() {
      $state.go('people.list');
    }

    function deleteFailure(response) {
      console.log('failed delete', response);
      $state.go('people.list');
    }

    function showConfirmDelete(ev) {
      var confirm = $mdDialog.confirm()
        .title('Delete Person')
        .textContent('Do you really want to delete ' + vm.person.first_name + ' ' + vm.person.surname + '?')
        .ariaLabel('Confirm delete person')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');
      $mdDialog.show(confirm).then(function () {
        deletePerson();
      });
    }
  }
}());
